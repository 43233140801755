import Image from "next/image";
import { useSelector } from "react-redux";


const GoogleWidget = () => {
    const { trustpilot_details} = useSelector((state) => state.globalReducer);
    return (
        <div  className="googleClass">
            <a
                id="profile-link"
                target="_blank"
                href='https://g.page/r/CW-xjYYWiYAUEAE/'
            >
                <div id="google-widget-stars">
                    <Image src='/images/google_reviews.png' style={{
                            objectFit: "contain"
                        }} alt="Google reviews" width={150} height={50} />
                </div>
                <div
                    id="reviews-summary"
                >
                    <div
                        aria-hidden="true"
                        className="google-widget-businessinfo"
                    >
                        <strong id="google-score">
                            {" "}
                            {trustpilot_details.score}
                        </strong>
                    </div>
                    <div className="google-widget-businessinfo__total">
                        <strong>
                            {" "}
                            {trustpilot_details.no_of_reviews}
                        </strong>
                        <span> reviews</span>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default GoogleWidget;
